@media (min-width: 1600px) {
    .nexxtway-main_content-container {
        width: 1480px;
        height: 60vh;
        background-position: center;
        background-size: cover;
        margin: 160px auto 0 auto;
    }
}

@media (max-width: 1024px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 200px);
        background-image: url(/assets/images/background-home-mobile.svg);
        background-position: center;
        margin-top: 100px;
    }
    .nexxtway-main_content {
        width: 50%;
        margin: 44px;
        padding-top: 16%;
    }
    .nexxtway-main_description {
        color: #032e61;
    }
}

@media (max-width: 800px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 240px);
        margin-top: 140px;
    }
    .nexxtway-main_content {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;
    }
    .nexxtway-main_slogan {
        position: absolute;
        top: -48px;
        font-size: 20px;
        margin-bottom: 32px;
        text-align: center;
        padding: 0 20px;
    }
    .nexxtway-main_description {
        font-size: 16px;
        text-align: center;
        width: 90%;
        position: absolute;
        top: 0;
        padding: 0 20px;
    }
    .nexxtway-main_button {
        position: absolute;
        bottom: 0;
        margin: 0;
    }
}

@media (max-width: 340px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 180px);
        margin-top: 140px;
    }
}
