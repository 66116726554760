.nexxtway-about_container {
    margin: 180px 120px;
}

.nexxtway-about_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    font-weight: 800;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
    text-transform: uppercase;
}

.nexxtway-about_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nexxtway-about_text {
    width: 45%;
}

.nexxtway-about_title {
    font-size: 26px;
    color: #032e61;
    margin-bottom: 20px;
}

.nexxtway-about_description {
    font-size: 18px;
    line-height: 1.5;
    color: #a4a7b5;
    font-weight: 300;
    margin-bottom: 12px;
}
