@media (min-width: 1440px) {
    .nexxtway-global-header_container {
        display: flex;
        justify-content: center;
    }
    .nexxtway-global-header_navigation-container {
        width: 1440px;
    }
}

@media (max-width: 1024px) {
    .nexxtway-global-header_container {
        width: 100%;
        padding: 0 48px;
    }
}

@media (max-width: 800px) {
    .nexxtway-global-header_container {
        padding: 0 16px;
        height: 74px;
    }
    .nexxtway-global-header_logo-container {
        margin: 0 8px;
    }
    .nexxtway-global-header_link {
        display: none;
    }
    .nexxtway-global-header_hamburger-menu {
        display: contents;
    }
}
