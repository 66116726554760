.nexxtway-global-header_hamburger-menu {
    display: none;
}

.nexxtway-global-header_modal-header {
    border-bottom: solid 1px #e3e5ed;
    padding: 2px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nexxtway-global-header_modal-header-logo {
    height: 24px;
    margin-bottom: 1rem;
}

.nexxtway-global-header_modal-link-container {
    padding-top: 12px;
}

.nexxtway-global-header_modal-link {
    font-size: 20px;
    color: #032E61;
    padding: 12px 0;
    display: flex;
    align-items: center;
}

.nexxtway-global-header_modal-icon-link {
    margin-right: 16px;
}
