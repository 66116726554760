.nexxtway-global-header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    height: 74px;
    z-index: 1000;
    padding: 0 120px;
}

.nexxtway-global-header_logo-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: inherit;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-right: 44px;
}

.nexxtway-global-header_logo-container:hover,
.nexxtway-global-header_logo-container:active,
.nexxtway-global-header_logo-container:focus {
    text-decoration: none;
    color: inherit;
}

.nexxtway-global-header_navigation-container {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.nexxtway-global-header_link {
    font-size: 16px;
    font-weight: 900;
    color: #00b0e6;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: 32px;
}

.nexxtway-global-header_link:hover,
.nexxtway-global-header_link:active,
.nexxtway-global-header_link--active,
.nexxtway-global-header_link:focus {
    border-color: inherit;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-decoration: none;
    color: #048ce6;
}

.nexxtway-global-header_hamburger-menu {
    display: none;
}
