.nexxtway-contactus_container {
    margin: 180px 120px 82px 120px;
}

.nexxtway-contactus_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
    color: #032e61;
    margin-bottom: 24px;
}

.nexxtway-contactus_description {
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
    line-height: 1.5;
    color: #a4a7b5;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nexxtway-contactus_details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
}

.nexxtway-contactus_details-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
}

.nexxtway-contactus_details {
    font-size: 16px;
    color: #032e61;
    margin-left: 12px;
}

.nexxtway-contactus_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.nexxtway-contactus_form {
    width: 55%;
    margin-right: 54px;
}

.nexxtway-contactus_input-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.nexxtway-contactus_footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.nexxtway-contactus_input {
    width: 48%;
    margin-bottom: 32px;
}

.nexxtway-contactus_input-large {
    width: 100%;
    margin-bottom: 32px;
}

.nexxtway-contactus_image {
    width: 30%;
}

.nexxtway-contactus_button-icon {
    fill: white;
    margin-left: 8px;
}
