@media (min-width: 1440px) {
    .nexxtway-contactus_container {
        width: 1440px;
        padding: 0 120px;
        margin: 64px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-contactus_container {
        margin: 100px 0 64px 0;
        width: 100%;
        padding: 0 64px;
    }
    .nexxtway-contactus_content{
        margin-top: 0;
    }
    .nexxtway-contactus_header {
        margin-bottom: 20px;
    }
    .nexxtway-contactus_form {
        width: 75%;
        margin: 0 32px 0 0;
    }
    .nexxtway-contactus_input {
        width: 49%;
        margin-bottom: 24px;
    }
    .nexxtway-contactus_image {
        width: 30%;
    }
}

@media (max-width: 800px) {
    .nexxtway-contactus_container {
        margin: 80px 0 48px 0;
        padding: 0 16px;
    }
    .nexxtway-contactus_header {
        font-size: 32px;
    }
    .nexxtway-contactus_content {
        flex-direction: column-reverse;
        justify-content: center;
        margin-top: 16px;
        margin-right: 0;
        width: 100%;
    }
    .nexxtway-contactus_form {
        margin: 0;
        width: 100%;
        padding: 24px 32px;
    }
    .nexxtway-contactus_input-container, .nexxtway-contactus_footer-container {
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    .nexxtway-contactus_input, .nexxtway-contactus_button {
        width: 100%;
    }
    .nexxtway-contactus_image {
        width: 60%;
    }
    .nexxtway-contactus_recaptcha {
        margin-bottom: 24px;
    }
}

@media (max-width: 400px) {
    .nexxtway-contactus_form {
        padding: 16px;
    }
}
