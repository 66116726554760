.nexxtway-button {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    justify-content: center;
    position: relative;
    background-color: #01b6f5;
    border: 1px solid #01b6f5;
    background-clip: border-box;
    border-radius: 100px;
    line-height: 48px;
    text-decoration: none;
    color: white;
    padding: 0 1rem;
    cursor: pointer;
    -webkit-appearance: none;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    transition: border 0.15s linear;
    box-shadow: 0 0 2px 0 #a4a7b5;
}

.nexxtway-button:active {
    color: #009acf;
    transform: scale(0.95);
    transition: all 0.2s ease;
}

.nexxtway-button:hover, .nexxtway-button:focus, .rainbow-button--brand:active {
    background-color: #009acf;
    border-color: #009acf;
    color: white;
    text-decoration: none;
}

.nexxtway-button:link, .nexxtway-button:visited, .rainbow-button--brand:active {
    color: white;
    text-decoration: none;
    background-color: #01b6f5;
    border: 1px solid #01b6f5;
}

.nexxtway-button[disabled] {
    background-color: #f4f6f9;
    border-color: #f4f6f9;
    color: #e3e5ed;
}
