.nexxtway-message_container {
    padding: 20px 16px;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    top: 0;
    z-index: 999999;
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-transition: opacity 250ms linear, -webkit-transform 500ms linear;
    transition: opacity 250ms linear, -webkit-transform 500ms linear;
    transition: transform 500ms linear, opacity 250ms linear;
    transition: transform 500ms linear, opacity 250ms linear, -webkit-transform 500ms linear;
}

.nexxtway-message_container-error {
    background-color: #fe4849;
}

.nexxtway-message_container-success {
    background-color: #1de9b6;
}

.nexxtway-message_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 980px;
}

.nexxtway-message_text {
    color: #ffffff;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.nexxtway-message_icon, .nexxtway-message_close-button {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.nexxtway-message_container--shown {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}

.nexxtway-global-header_hamburger-menu {
    display: none;
}

.nexxtway-global-header_modal-header {
    border-bottom: solid 1px #e3e5ed;
    padding: 2px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nexxtway-global-header_modal-header-logo {
    height: 24px;
    margin-bottom: 1rem;
}

.nexxtway-global-header_modal-link-container {
    padding-top: 12px;
}

.nexxtway-global-header_modal-link {
    font-size: 20px;
    color: #032E61;
    padding: 12px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.nexxtway-global-header_modal-icon-link {
    margin-right: 16px;
}

.nexxtway-global-header_container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    height: 74px;
    z-index: 1000;
    padding: 0 120px;
}

.nexxtway-global-header_logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    color: inherit;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-right: 44px;
}

.nexxtway-global-header_logo-container:hover,
.nexxtway-global-header_logo-container:active,
.nexxtway-global-header_logo-container:focus {
    text-decoration: none;
    color: inherit;
}

.nexxtway-global-header_navigation-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 18px;
}

.nexxtway-global-header_link {
    font-size: 16px;
    font-weight: 900;
    color: #00b0e6;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: 32px;
}

.nexxtway-global-header_link:hover,
.nexxtway-global-header_link:active,
.nexxtway-global-header_link--active,
.nexxtway-global-header_link:focus {
    border-color: inherit;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-decoration: none;
    color: #048ce6;
}

.nexxtway-global-header_hamburger-menu {
    display: none;
}

@media (min-width: 1440px) {
    .nexxtway-global-header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .nexxtway-global-header_navigation-container {
        width: 1440px;
    }
}

@media (max-width: 1024px) {
    .nexxtway-global-header_container {
        width: 100%;
        padding: 0 48px;
    }
}

@media (max-width: 800px) {
    .nexxtway-global-header_container {
        padding: 0 16px;
        height: 74px;
    }
    .nexxtway-global-header_logo-container {
        margin: 0 8px;
    }
    .nexxtway-global-header_link {
        display: none;
    }
    .nexxtway-global-header_hamburger-menu {
        display: contents;
    }
}

.nexxtway-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    padding: 24px 0;
    background-color: white;
    border-top: 1px solid #f8f9fc;
    position: relative;
}

.nexxtway-footer_copyright {
    font-size: 14px;
    color: #a4a7b5;
    font-weight: 200;
}

.nexxtway-footer_social-link-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    left: 120px;
}

.nexxtway-footer_social-link {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    background-color: #a4a7b5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
    margin-right: 16px;
}

.nexxtway-footer_social-link-linkedin:hover {
    background-color: #007AB9;
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
}

.nexxtway-footer_social-link-github {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    fill: #a4a7b5;
    color: #a4a7b5;
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
}

.nexxtway-footer_social-link-github:hover {
    fill: #000;
    color: #000;
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
}

@media (max-width: 1024px) {
    .nexxtway-footer_social-link {
        left: 48px;
    }
    .nexxtway-footer_social-link:hover {
        left: 46px;
    }
}

@media (max-width: 800px) {
    .nexxtway-footer_social-link-container {
        position: relative;
        left: auto;
        margin-bottom: 12px;
    }
}
.nexxtway-button {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-size: 1rem;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    background-color: #01b6f5;
    border: 1px solid #01b6f5;
    background-clip: border-box;
    border-radius: 100px;
    line-height: 48px;
    text-decoration: none;
    color: white;
    padding: 0 1rem;
    cursor: pointer;
    -webkit-appearance: none;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: border 0.15s linear;
    transition: border 0.15s linear;
    box-shadow: 0 0 2px 0 #a4a7b5;
}

.nexxtway-button:active {
    color: #009acf;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.nexxtway-button:hover, .nexxtway-button:focus, .rainbow-button--brand:active {
    background-color: #009acf;
    border-color: #009acf;
    color: white;
    text-decoration: none;
}

.nexxtway-button:link, .nexxtway-button:visited, .rainbow-button--brand:active {
    color: white;
    text-decoration: none;
    background-color: #01b6f5;
    border: 1px solid #01b6f5;
}

.nexxtway-button[disabled] {
    background-color: #f4f6f9;
    border-color: #f4f6f9;
    color: #e3e5ed;
}

.nexxtway-main-container {
    width: 100%;
}

.nexxtway-main_content-container {
    height: calc(100vh - 120px);
    width: 100%;
    background-image: url(/assets/images/background-home.svg);
    background-position: bottom right;
    background-size: cover;
    margin-top: 84px;
}

.nexxtway-main_content {
    width: 35%;
    margin-left: 100px;
    padding-top: 8%;
}

.nexxtway-main_slogan {
    font-family: CourierNewBold;
    font-size: 40px;
    line-height: 1.2;
    color: #032e61;
    padding: 20px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.5);
}

.nexxtway-main_description {
    font-family: CourierNewBold;
    font-size: 28px;
    line-height: 1.2;
    color: #032e61;
    padding: 0 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0.7);
}

.nexxtway-main_button {
    margin: 48px 0 0 20px;
}

@media (min-width: 1600px) {
    .nexxtway-main_content-container {
        width: 1480px;
        height: 60vh;
        background-position: center;
        background-size: cover;
        margin: 160px auto 0 auto;
    }
}

@media (max-width: 1024px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 200px);
        background-image: url(/assets/images/background-home-mobile.svg);
        background-position: center;
        margin-top: 100px;
    }
    .nexxtway-main_content {
        width: 50%;
        margin: 44px;
        padding-top: 16%;
    }
    .nexxtway-main_description {
        color: #032e61;
    }
}

@media (max-width: 800px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 240px);
        margin-top: 140px;
    }
    .nexxtway-main_content {
        width: 100%;
        margin: 0;
        padding: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        height: 100%;
        position: relative;
    }
    .nexxtway-main_slogan {
        position: absolute;
        top: -48px;
        font-size: 20px;
        margin-bottom: 32px;
        text-align: center;
        padding: 0 20px;
    }
    .nexxtway-main_description {
        font-size: 16px;
        text-align: center;
        width: 90%;
        position: absolute;
        top: 0;
        padding: 0 20px;
    }
    .nexxtway-main_button {
        position: absolute;
        bottom: 0;
        margin: 0;
    }
}

@media (max-width: 340px) {
    .nexxtway-main_content-container {
        height: calc(100vh - 180px);
        margin-top: 140px;
    }
}

.nexxtway-about_container {
    margin: 180px 120px;
}

.nexxtway-about_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    font-weight: 800;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
    text-transform: uppercase;
}

.nexxtway-about_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.nexxtway-about_text {
    width: 45%;
}

.nexxtway-about_title {
    font-size: 26px;
    color: #032e61;
    margin-bottom: 20px;
}

.nexxtway-about_description {
    font-size: 18px;
    line-height: 1.5;
    color: #a4a7b5;
    font-weight: 300;
    margin-bottom: 12px;
}

@media (min-width: 1440px) {
    .nexxtway-about_container {
        width: 1440px;
        padding: 0 120px;
        margin: 120px 0 100px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-about_image {
        width: 50%;
    }
    .nexxtway-about_container {
        margin: 100px 48px;
    }
}

@media (max-width: 800px) {
    .nexxtway-about_container {
        margin: 120px 24px 84px 24px;
    }
    .nexxtway-about_header {
        font-size: 32px;
        margin-bottom: 48px;
    }
    .nexxtway-about_content {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .nexxtway-about_text {
        width: 100%;
    }
    .nexxtway-about_image {
        width: 80%;
    }
    .nexxtway-about_title {
        font-size: 20px;
        margin: 16px;
        text-align: center;
    }
    .nexxtway-about_description {
        font-size: 14px;
        text-align: center;
    }
}

.nexxtway-offer-item_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 32px 24px;
}

.nexxtway-offer-item_image {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    height: 140px;
}

.nexxtway-offer-item_title {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    overflow: hidden;
    margin: 24px 0 16px 0;
    color: #032e61;
}

.nexxtway-offer-item_description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 16px;
    color: #a4a7b5;
}

.nexxtway-offer-item_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
    color: #00b0e6;
}

.nexxtway-offer-item_link:hover,
.nexxtway-offer-item_link:active,
.nexxtway-offer-item_link:focus,
.nexxtway-offer-item_link:visited {
    color: #009cda;
}

.nexxtway-offer-item_link-icon {
    margin-left: 12px;
}

.nexxtway-services_container {
    margin: 180px 120px;
}

.nexxtway-services_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
}

.nexxtway-services_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 0 64px;
}

.nexxtway-services_item {
    width: 37%;
}

@media (min-width: 1440px) {
    .nexxtway-services_container {
        width: 1440px;
        padding: 0 120px;
        margin: 64px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-services_header {
        margin-bottom: 48px;
    }
    .nexxtway-services_container {
        margin: 100px 16px 48px 16px;
        padding: 0;
    }
    .nexxtway-services_content {
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        padding: 0;
    }
    .nexxtway-services_item {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .nexxtway-services_header {
        font-size: 32px;
    }
    .nexxtway-services_content {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .nexxtway-services_item {
        width: 100%;
    }
}

.nexxtway-solutions_container {
    margin: 180px 120px;
}

.nexxtway-solutions_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
}

.nexxtway-solutions_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.nexxtway-solutions_item {
    width: 35%;
}

@media (min-width: 1440px) {
    .nexxtway-solutions_container {
        width: 1440px;
        padding: 0 120px;
        margin: 64px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-solutions_header {
        margin-bottom: 48px;
    }
    .nexxtway-solutions_container {
        margin: 100px 16px 48px 16px;
    }
    .nexxtway-solutions_content {
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        padding: 0;
    }
    .nexxtway-solutions_item {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .nexxtway-solutions_header {
        font-size: 32px;
    }
    .nexxtway-solutions_content {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .nexxtway-solutions_item {
        width: 100%;
    }
}

.nexxtway-contactus_container {
    margin: 180px 120px 82px 120px;
}

.nexxtway-contactus_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
    color: #032e61;
    margin-bottom: 24px;
}

.nexxtway-contactus_description {
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
    line-height: 1.5;
    color: #a4a7b5;
    font-weight: 300;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.nexxtway-contactus_details-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 16px;
}

.nexxtway-contactus_details-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 32px;
}

.nexxtway-contactus_details {
    font-size: 16px;
    color: #032e61;
    margin-left: 12px;
}

.nexxtway-contactus_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 16px;
}

.nexxtway-contactus_form {
    width: 55%;
    margin-right: 54px;
}

.nexxtway-contactus_input-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.nexxtway-contactus_footer-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.nexxtway-contactus_input {
    width: 48%;
    margin-bottom: 32px;
}

.nexxtway-contactus_input-large {
    width: 100%;
    margin-bottom: 32px;
}

.nexxtway-contactus_image {
    width: 30%;
}

.nexxtway-contactus_button-icon {
    fill: white;
    margin-left: 8px;
}

@media (min-width: 1440px) {
    .nexxtway-contactus_container {
        width: 1440px;
        padding: 0 120px;
        margin: 64px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-contactus_container {
        margin: 100px 0 64px 0;
        width: 100%;
        padding: 0 64px;
    }
    .nexxtway-contactus_content{
        margin-top: 0;
    }
    .nexxtway-contactus_header {
        margin-bottom: 20px;
    }
    .nexxtway-contactus_form {
        width: 75%;
        margin: 0 32px 0 0;
    }
    .nexxtway-contactus_input {
        width: 49%;
        margin-bottom: 24px;
    }
    .nexxtway-contactus_image {
        width: 30%;
    }
}

@media (max-width: 800px) {
    .nexxtway-contactus_container {
        margin: 80px 0 48px 0;
        padding: 0 16px;
    }
    .nexxtway-contactus_header {
        font-size: 32px;
    }
    .nexxtway-contactus_content {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: 16px;
        margin-right: 0;
        width: 100%;
    }
    .nexxtway-contactus_form {
        margin: 0;
        width: 100%;
        padding: 24px 32px;
    }
    .nexxtway-contactus_input-container, .nexxtway-contactus_footer-container {
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }
    .nexxtway-contactus_input, .nexxtway-contactus_button {
        width: 100%;
    }
    .nexxtway-contactus_image {
        width: 60%;
    }
    .nexxtway-contactus_recaptcha {
        margin-bottom: 24px;
    }
}

@media (max-width: 400px) {
    .nexxtway-contactus_form {
        padding: 16px;
    }
}

html {
    background-color: white;
}
@media (min-width: 1440px ){
    .nexxtway-home_viewport {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media (max-width: 700px) {
    .nexxtway-home_viewport {
        width: 100vw;
    }
}

@font-face {
    font-family: 'CourierNew';
    src: url('/assets/fonts/CourierNew/CourierNew.eot');
    src: url('/assets/fonts/CourierNew/CourierNew.woff2') format('woff2'),
         url('/assets/fonts/CourierNew/CourierNew.woff') format('woff'),
         url('/assets/fonts/CourierNew/CourierNew.ttf') format('truetype'),
         url('/assets/fonts/CourierNew/CourierNew.svg#FONTSPRINGDEMO-CourierNew') format('svg'),
         url('/assets/fonts/CourierNew/CourierNew.eot?#iefix') format('embedded-opentype');
    font-style: normal;
}

@font-face {
     font-family: 'CourierNewBold';
     src: url('/assets/fonts/CourierNewBold/CourierNewBold.eot');
     src: url('/assets/fonts/CourierNewBold/CourierNewBold.woff2') format('woff2'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.woff') format('woff'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.ttf') format('truetype'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.svg#FONTSPRINGDEMO-CourierNewBold') format('svg'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.eot?#iefix') format('embedded-opentype');
     font-style: normal;
 }
 

