.nexxtway-offer-item_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 24px;
}

.nexxtway-offer-item_image {
    flex-shrink: 0;
    height: 140px;
}

.nexxtway-offer-item_title {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    overflow: hidden;
    margin: 24px 0 16px 0;
    color: #032e61;
}

.nexxtway-offer-item_description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 16px;
    color: #a4a7b5;
}

.nexxtway-offer-item_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
    color: #00b0e6;
}

.nexxtway-offer-item_link:hover,
.nexxtway-offer-item_link:active,
.nexxtway-offer-item_link:focus,
.nexxtway-offer-item_link:visited {
    color: #009cda;
}

.nexxtway-offer-item_link-icon {
    margin-left: 12px;
}
