@media (min-width: 1440px) {
    .nexxtway-about_container {
        width: 1440px;
        padding: 0 120px;
        margin: 120px 0 100px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-about_image {
        width: 50%;
    }
    .nexxtway-about_container {
        margin: 100px 48px;
    }
}

@media (max-width: 800px) {
    .nexxtway-about_container {
        margin: 120px 24px 84px 24px;
    }
    .nexxtway-about_header {
        font-size: 32px;
        margin-bottom: 48px;
    }
    .nexxtway-about_content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .nexxtway-about_text {
        width: 100%;
    }
    .nexxtway-about_image {
        width: 80%;
    }
    .nexxtway-about_title {
        font-size: 20px;
        margin: 16px;
        text-align: center;
    }
    .nexxtway-about_description {
        font-size: 14px;
        text-align: center;
    }
}
