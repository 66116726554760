@font-face {
    font-family: 'CourierNew';
    src: url('/assets/fonts/CourierNew/CourierNew.eot');
    src: url('/assets/fonts/CourierNew/CourierNew.woff2') format('woff2'),
         url('/assets/fonts/CourierNew/CourierNew.woff') format('woff'),
         url('/assets/fonts/CourierNew/CourierNew.ttf') format('truetype'),
         url('/assets/fonts/CourierNew/CourierNew.svg#FONTSPRINGDEMO-CourierNew') format('svg'),
         url('/assets/fonts/CourierNew/CourierNew.eot?#iefix') format('embedded-opentype');
    font-style: normal;
}

@font-face {
     font-family: 'CourierNewBold';
     src: url('/assets/fonts/CourierNewBold/CourierNewBold.eot');
     src: url('/assets/fonts/CourierNewBold/CourierNewBold.woff2') format('woff2'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.woff') format('woff'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.ttf') format('truetype'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.svg#FONTSPRINGDEMO-CourierNewBold') format('svg'),
          url('/assets/fonts/CourierNewBold/CourierNewBold.eot?#iefix') format('embedded-opentype');
     font-style: normal;
 }
 
