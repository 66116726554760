.nexxtway-footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px 0;
    background-color: white;
    border-top: 1px solid #f8f9fc;
    position: relative;
}

.nexxtway-footer_copyright {
    font-size: 14px;
    color: #a4a7b5;
    font-weight: 200;
}

.nexxtway-footer_social-link-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 120px;
}

.nexxtway-footer_social-link {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    background-color: #a4a7b5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.05s ease;
    margin-right: 16px;
}

.nexxtway-footer_social-link-linkedin:hover {
    background-color: #007AB9;
    transition: all 0.05s ease;
}

.nexxtway-footer_social-link-github {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    fill: #a4a7b5;
    color: #a4a7b5;
    transition: all 0.05s ease;
}

.nexxtway-footer_social-link-github:hover {
    fill: #000;
    color: #000;
    transition: all 0.05s ease;
}

@media (max-width: 1024px) {
    .nexxtway-footer_social-link {
        left: 48px;
    }
    .nexxtway-footer_social-link:hover {
        left: 46px;
    }
}

@media (max-width: 800px) {
    .nexxtway-footer_social-link-container {
        position: relative;
        left: auto;
        margin-bottom: 12px;
    }
}