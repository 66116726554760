.nexxtway-message_container {
    padding: 20px 16px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 999999;
    transform: translateY(-500px);
    transition: transform 500ms linear, opacity 250ms linear;
}

.nexxtway-message_container-error {
    background-color: #fe4849;
}

.nexxtway-message_container-success {
    background-color: #1de9b6;
}

.nexxtway-message_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 980px;
}

.nexxtway-message_text {
    color: #ffffff;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}

.nexxtway-message_icon, .nexxtway-message_close-button {
    flex-shrink: 0;
}

.nexxtway-message_container--shown {
    transform: translateY(0%);
}
