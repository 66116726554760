html {
    background-color: white;
}
@media (min-width: 1440px ){
    .nexxtway-home_viewport {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 700px) {
    .nexxtway-home_viewport {
        width: 100vw;
    }
}
