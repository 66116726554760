.nexxtway-services_container {
    margin: 180px 120px;
}

.nexxtway-services_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
}

.nexxtway-services_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0 64px;
}

.nexxtway-services_item {
    width: 37%;
}
