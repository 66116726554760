.nexxtway-solutions_container {
    margin: 180px 120px;
}

.nexxtway-solutions_header {
    font-family: CourierNewBold;
    font-size: 48px;
    line-height: 1.2;
    color: #032e61;
    margin-bottom: 64px;
    text-align: center;
}

.nexxtway-solutions_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
}

.nexxtway-solutions_item {
    width: 35%;
}
