@media (min-width: 1440px) {
    .nexxtway-services_container {
        width: 1440px;
        padding: 0 120px;
        margin: 64px 0;
    }
}

@media (max-width: 1024px) {
    .nexxtway-services_header {
        margin-bottom: 48px;
    }
    .nexxtway-services_container {
        margin: 100px 16px 48px 16px;
        padding: 0;
    }
    .nexxtway-services_content {
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0;
    }
    .nexxtway-services_item {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .nexxtway-services_header {
        font-size: 32px;
    }
    .nexxtway-services_content {
        justify-content: center;
    }
    .nexxtway-services_item {
        width: 100%;
    }
}
