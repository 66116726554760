.nexxtway-main-container {
    width: 100%;
}

.nexxtway-main_content-container {
    height: calc(100vh - 120px);
    width: 100%;
    background-image: url(/assets/images/background-home.svg);
    background-position: bottom right;
    background-size: cover;
    margin-top: 84px;
}

.nexxtway-main_content {
    width: 35%;
    margin-left: 100px;
    padding-top: 8%;
}

.nexxtway-main_slogan {
    font-family: CourierNewBold;
    font-size: 40px;
    line-height: 1.2;
    color: #032e61;
    padding: 20px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.5);
}

.nexxtway-main_description {
    font-family: CourierNewBold;
    font-size: 28px;
    line-height: 1.2;
    color: #032e61;
    padding: 0 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0.7);
}

.nexxtway-main_button {
    margin: 48px 0 0 20px;
}
